<template>
  <b-modal id="popup-pool-types" ref="popup-pool-types" centered="centered" :title="(poolType.id ? 'Modifier' : 'Ajouter') + ` un type de bassin`" @ok="create">
    <div v-if="isCreatingPoolType">
      <div class="text-center flex-center">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div class="mt-5">
          <br /><br /><br />
          Chargement...
        </div>
      </div>
    </div>
    <validation-observer v-else ref="formPoolType">
      <b-row>
        <b-col cols="12" class="d-flex mb-1">
          <b-form-group label="Libellé *" label-for="Libellé" style="flex: 1">
            <validation-provider #default="{ errors }" name="Libellé" rules="required">
              <b-form-input
                id="label"
                v-model="poolType.label"
                :state="errors.length > 0 ? false : null"
                class="not-autocomplete"
                autocomplete="nope"
                aria-autocomplete="nope"
                v-on:keyup.enter="create"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12" class="d-flex mb-1">
          <b-form-group label="Forme pour calcul du volume *" label-for="shape" style="flex: 1">
            <validation-provider #default="{ errors }" name="shape" rules="required">
              <b-form-select
                v-model="poolType.shape"
                :options="[
                  { value: 'rectangulaire', text: 'Rectangulaire' },
                  { value: 'ronde', text: 'Ronde' },
                  { value: 'ovale', text: 'Ovale' },
                ]"
              ></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer="{ ok, cancel }">
      <div class="w-100 mx-0">
        <b-button class="float-left" v-if="poolType.id" variant="outline-danger" @click="deleteInterventionTypeLocal(poolType.id, poolType.label)"
          ><feather-icon icon="Trash2Icon"
        /></b-button>
        <b-button class="float-right" variant="primary" @click="ok()">{{ poolType.id ? "Modifier" : "Ajouter" }}</b-button>
        <b-button class="float-right mr-1" variant="outline-primary" @click="cancel()">Annuler</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver, configure, localize } from "vee-validate";
import { required, url, email } from "@validations";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import "vue-swatches/dist/vue-swatches.css";
configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

export default {
  data() {
    return {
      required,
      url,
      email,
      poolType: {
        label: "",
        workspaceId: this.$store.getters.workspaceSelected.id,
        shape: "",
      },
    };
  },
  methods: {
    popupCreatePoolTypesEvent(id) {
      if (id > 0) {
        this.fetchPoolType(id).then((res) => {
          this.poolType = res;
        });
      } else this.initializeForm();
    },
    initializeForm() {
      this.poolType = {
        label: "",
        workspaceId: this.$store.getters.workspaceSelected.id,
      };
    },
    deleteInterventionTypeLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm("Cette action est irréversible.", {
          title: `Êtes-vous sûr de vouloir supprimer le type de bassin ${label} ?`,
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.archivePoolType(id);
            this.$nextTick(() => {
              this.$bvModal.hide("popup-pool-types");
            });
          }
        });
    },
    create(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.formPoolType.validate().then((success1) => {
        if (success1) {
          if (this.poolType.id) this.updatePoolType(this.poolType);
          else this.createPoolType(this.poolType);
          this.$nextTick(() => {
            this.$refs["popup-pool-types"].toggle("#toggle-btn");
          });
        }
      });
    },
    ...mapActions(["createPoolType", "updatePoolType", "fetchPoolType", "archivePoolType"]),
  },
  computed: {
    ...mapGetters(["isCreatingPoolType"]),
  },

  components: {
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
};
</script>
